import React from "react";
import SiteHead from "../component/head/SiteHead";
import { EntityType } from "../redux/action/CurrentActions";
import { fetchEntityService } from "../service/FetchEntityService";
import { logError } from "../service/ServiceUtil";

const Home = ({ content, channel, requestUrl }) => {
  return (
    <div suppressHydrationWarning={true}>
      <SiteHead content={content} channel={channel} requestUrl={requestUrl} />
    </div>
  );
};

export default Home;

export const getServerSideProps = async ({ req, res }) => {
  res.setHeader(
    "Cache-Control",
    "private, no-store, no-transform"
  );
  res.setHeader('X-Robots-Tag', 'noindex, nofollow');
  res.setHeader(
    "Content-Security-Policy",
    "frame-ancestors 'self' *.pixelmixer.com"
  );
  let contentAndChannelShareObject = {};
  try {
    contentAndChannelShareObject = await getContentAndChannelShares(req);
    const { channelShare, contentShare } = contentAndChannelShareObject;
    if (channelShare?.cspDomain || contentShare?.cspDomain)
      res.setHeader(
        "Content-Security-Policy",
        `frame-ancestors 'self' *.pixelmixer.com ${
          channelShare?.cspDomain || contentShare?.cspDomain
        }`
      );
  } catch (e) {
    logError("getServerSideProps", e);
  }
  return {
    props: {
      time: new Date().toISOString(),
      ...contentAndChannelShareObject,
    },
  };
};

const getContentAndChannelShares = async (req) => {
  const shareCodesObj = getShareCodesObject(req);
  const baseUrl = `https://${req.headers.host}`;
  const requestUrl = baseUrl + req.url;
  const getState = () => ({
    login: {
      baseUrl,
    },
  });
  if (shareCodesObj) {
    const urlArray = req.url.substring(1, req.url.indexOf("?")).split("/");
    if (urlArray[0] === "video" || urlArray[0] === "shares") {
      const contentShare = await fetchEntityService.fetchEntity(
        null,
        getState,
        EntityType.ContentShare,
        null,
        null,
        { ...shareCodesObj, contentId: urlArray[1] }
      );
      return {
        contentShare,
        content: contentShare.content,
        requestUrl,
      };
    } else if (urlArray[0] === "channel") {
      if (urlArray.length === 4) {
        const channelShare = await fetchEntityService.fetchEntity(
          null,
          getState,
          EntityType.ChannelShare,
          null,
          null,
          { ...shareCodesObj, channelId: urlArray[1] }
        );
        const content = await fetchEntityService.fetchEntity(
          null,
          getState,
          EntityType.Content,
          urlArray[3],
          null,
          shareCodesObj
        );
        return {
          channelShare,
          content,
          requestUrl,
        };
      } else {
        const channelShare = await fetchEntityService.fetchEntity(
          null,
          getState,
          EntityType.ChannelShare,
          null,
          null,
          { ...shareCodesObj, channelId: urlArray[1] }
        );
        return {
          channelShare,
          channel: channelShare.channel,
          requestUrl,
        };
      }
    }
  }
  return {};
};

const getShareCodesObject = (req) => {
  const shareCode = getQueryParam(req, "shareCode");
  const channelShareCode = getQueryParam(req, "channelShareCode");
  if (shareCode) return { shareCode };
  else if (channelShareCode) return { channelShareCode };
};

const getQueryParam = (req, queryParam) => {
  const url = req.url;
  const params =
    url.indexOf("?") > 0
      ? new URLSearchParams(url.substring(url.indexOf("?"), url.length))
      : null;
  if (params) return params.get(queryParam);
};
