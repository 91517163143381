import React from "react";
import Head from "next/head";
import { useAnalytics, useScript } from "../../hooks";
import appicon from "./appicon.json";
import Script from "next/script";
import { useState } from "react";
import { stripHTMLTags } from "../../util/Utils";
import "default-passive-events";

const OGMeta = ({ content, channel, requestUrl }) => {
  const title = content ? content.name : channel ? channel.name : "PixelMixer";
  const posterUrl = content
    ? content.posterUrl
    : channel
    ? channel.splashUrl
    : "https://www.pixelmixer.com/static/img/play-blade-only-color.png";
  const description = stripHTMLTags(
    content && !content.requireAiReview
      ? content.description
      : channel
      ? channel.description
      : "Knowledge Community"
  );
  return (
    <>
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={posterUrl} />
      <meta
        property="og:url"
        content={`${requestUrl ? requestUrl : "https://www.pixelmixer.com/"}`}
      />
      <meta name="twitter:card" content="summary_large_image" />
    </>
  );
};

const SiteHead = ({ content, channel, requestUrl }) => {
  const [is51DegLoaded, setIs51DegLoaded] = useState(false);
  const [isJqueryLoaded, setIsJqueryLoaded] = useState(false);

  useScript({
    type: "application/ld+json",
    textContent: JSON.stringify(appicon),
  });

  useAnalytics();
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="content-type" content="text/html; charSet=utf-8" />
        <meta name="author" content="PixelMixer" />
        <title>PixelMixer Video</title>
        <OGMeta content={content} channel={channel} requestUrl={requestUrl} />
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <link
          rel="shortcut icon"
          href="/static/img/play-blade-only-color.png"
          type="image/x-icon"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/static/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/static/favicon/safari-pinned-tab.svg"
          color="#793de6"
        />
        <link rel="shortcut icon" href="/static/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-config"
          content="/static/favicon/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />
        <meta
          httpEquiv="Delegate-CH"
          content="sec-ch-ua-full-version-list https://cloud.51degrees.com; sec-ch-ua-model https://cloud.51degrees.com; sec-ch-ua-platform https://cloud.51degrees.com; sec-ch-ua-platform-version https://cloud.51degrees.com"
        />
      </Head>

      <Script
        onLoad={() => setIs51DegLoaded(true)}
        src="https://cloud.51degrees.com/api/v4/AQTPkKrCdAyFj3o23Eg.js"
      ></Script>
      {is51DegLoaded && <Script src="/static/js/deviceapi.js"></Script>}
      <Script src="https://www.googletagmanager.com/gtag/js?id=AW-731004586"></Script>
      {/* <Script src="https://unpkg.com/default-passive-events"></Script> */}
      <Script
        onLoad={() => setIsJqueryLoaded(true)}
        src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"
      ></Script>
      {isJqueryLoaded && (
        <Script
          src="/static/plugins/intercom/survey-next.js"
        ></Script>
      )}
    </>
  );
};

export default SiteHead;
